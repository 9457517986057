

export default function() {


    function setPageTitle(title: string) {
        // accessing the link tag
        const titleEl: any = document.getElementById("indexTitle");
        if (titleEl) {
            titleEl.innerText = title;
        }
    }

    function setFavicon(url: string) {
        // accessing the link tag
        const favicon: any = document.getElementById("favicon");
        if (favicon) {
            favicon.href = url;
        }
    }

    function getMonthNames() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames;
    }

    function getMonthNamesShort() {
        const monthNames = getMonthNames();
        const monthNamesShort: string [] = []
        for (const mn of monthNames) {
            monthNamesShort.push(mn.substr(0,3))
        }
        return monthNamesShort;
    }

    function getExpandedDateFromDateStr(str: string) {
        // const s = '2021-01-01';
        if (!str) {
            return '';
        }

        // console.log('typeof str', typeof str);
        if (typeof str !== 'string') {
            return '';
        }
        const mnShort = getMonthNamesShort();
        // console.log('str', str);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const ar = str.split('-');
        const month = mnShort[parseInt(ar[1], 10) - 1]
        return month + ' ' + ar[2] + ', ' + ar[0];
    }

    function getCurrentMonthStartAndEndString(monthsToAdd?: number) {
        if (!monthsToAdd) {
            monthsToAdd = 0;
        }
        const dta = new Date();
        const dt = new Date(dta.setMonth(dta.getMonth()+monthsToAdd));
        const first = dt.getFullYear().toString() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-01';
        const last = dt.getFullYear().toString() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + new Date(dt.getFullYear(), dt.getMonth() + 1, 0).getDate().toString().padStart(2, '0');
        return {
            first, last
        };
    }

    function getCurrentMonthStartAndEndNum() {
        const strs = getCurrentMonthStartAndEndString();
        const first = parseInt(strs.first);
        const last = parseInt(strs.last);
        return {
            first, last
        };
    }

    function checkYyyyMmDdIsThisMonth(dt: string) {
        if (!dt) {
            return false;
        }

        const nowDate = new Date();
        const nowYear = nowDate.getFullYear().toString();
        const nowMonth = (nowDate.getMonth() + 1).toString().padStart(2, '0');

        const dtAr = dt.split('-');
        // console.log('checkYyyyMmDdIsThisMonth dtAr[0]', dtAr[0]);
        // console.log('checkYyyyMmDdIsThisMonth nowYear', nowYear);
        // console.log('checkYyyyMmDdIsThisMonth dtAr[1]', dtAr[1]);
        // console.log('checkYyyyMmDdIsThisMonth nowMonth', nowMonth);
        if (dtAr[0] === nowYear && dtAr[1] === nowMonth) {
            return true;
        } else {
            return false;
        }
    }


    function formatMoney(amount: any, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            const i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            const j: any = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

    function formatHours(amount: any, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            const i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            const j: any = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }







    function getDisplayTime(time: any) {
        if (!time) {
            return '';
        }

        // 13:00
        const ar = time.split(':');
        let h = ar[0];
        const min = ar[1];
        let mer = 'AM';
        if ( parseInt(h) > 11) {
            mer = 'PM';
            h = ( parseInt(h) - 12).toString();
        }
        if ( parseInt(h) === 0) {
            h = '12';
        }

        return h + ':' + min + ' ' + mer;
        // return time;
    }

    function getDisplayTimeLower(time: any) {
        return (getDisplayTime(time)).toLowerCase();
    }

    function getDisplayTimeLowerFromIsoStr(datetime: any) {
        const dt = new Date(datetime);

        let mins: any;
        if (dt.getMinutes() < 10) {
            mins = '0' + dt.getMinutes().toString();
        } else {
            mins = dt.getMinutes().toString();
        }

        return getDisplayTimeLower(dt.getHours() + ':' + mins);
    }

    function isoDateStringIsNotThisYear(datetime: any) {
        // Create date from input value
        const inputDate = new Date(datetime);

        // Get today's date
        const todaysDate = new Date();

        // call setHours to take the time out of the comparison
        if (inputDate.getFullYear() !== todaysDate.getFullYear()) {
            // Date equals today's date
            return true;
        } else {
            return false;
        }
    }


    function getDisplayDateScoreboardBaseFromIsoStrShort(datetime: any) {
        if (!datetime) {
            return '';
        }
        const dt: any = new Date(datetime);
        if (isoDateStringIsNotThisYear(datetime)) {
            // Format e.g., 06/30/2017 at 12:45pm
            const day: string = (dt.getMonth() + 1).toString() + '/' + dt.getDate().toString() + '/' + dt.getFullYear().toString().slice(-2);
            const time: string = getDisplayTimeLowerFromIsoStr(datetime);
            // return day + ' at ' + time;
            return day;
        } else {
            // Format e.g., June 30th at 12:45pm


            const day: string = (dt.getMonth() + 1).toString() + '/' + dt.getDate().toString();
            const time: string = getDisplayTimeLowerFromIsoStr(datetime);
            // return day + ' at ' + time;
            return day;
        }
    }


    function getIsDarkMode() {
        // console.log('document.body.classList', document.body.classList)
        let b = false;
        for (const d of document.body.classList) {
            console.log('d', d);
            if (d === 'dark') {
                b = true;
            }
        }
        return b;
    }

    function getStorageFileUrlPre() {
        return 'https://storage.googleapis.com/rt-webapp.appspot.com/';
    }
    return {
        setPageTitle,
        setFavicon,
        getExpandedDateFromDateStr,
        getCurrentMonthStartAndEndNum,
        checkYyyyMmDdIsThisMonth,
        formatMoney,
        formatHours,
        getDisplayDateScoreboardBaseFromIsoStrShort,
        getIsDarkMode,
        getStorageFileUrlPre,
    };
}
